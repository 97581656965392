<template>
  <div>
    <div class="heads">
      <p>账号与安全</p>
      <span class="skip" @click="skip">跳过</span>
    </div>
    <div class="main">
      <div class="content">
        为了账号安全，请进行微信绑定
      </div>
      <div class="wx_btn" @click="goWx">
        <img src="https://newoss.zhulong.com/forum/202303/07/15/1678174197449890.png" alt="">微信绑定
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import { Toast } from 'vant'
import { getMiniUrlLink } from '@/api/login'
export default {
  data() {
    return {
      redirect: this.$route.query.redirect,
      wx_url: ''
    }
  },
  created() {

  },
  mounted() {
    this.getMiniUrlLink()
    console.log(this.$route.query.redirect, 'this.$route.query.redirect')
  },
  methods: {
    skip() {
      window.location.href = this.redirect
    },
    goWx() {
      if (this.wx_url) {
        window.location.href = this.wx_url
      } else {
        Toast({
          message: '跳转微信失败\n请使用其他方式登录',
          icon: 'close',
          className: 'noticeWidth'
        })
      }
    },
    // 获取小程序跳转链接
    getMiniUrlLink() {
      const str = this.redirect
      var share = encodeURIComponent(str)
      const info = {
        path: '/pages/login/login',
        query: `uuid=${Cookies.get('uuid')}&access_token=${Cookies.get('access_token')}&fromType=2&${share}`,
        env_version: 'release'
      }
      getMiniUrlLink(info).then(res => {
        console.log(res, 'res')
        if (res.result) {
          this.wx_url = res.result
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.heads{
  width: 100%;
  height: 120px;
  line-height: 120px;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  z-index: 1000;
  text-align: center;
  color: rgba(16, 16, 16, 1);
  font-size: 36px;
  p{
    font-size: 32px;
    font-weight: 600;
  }
  .skip{
    position: absolute;
    top: 0;
    right: 32px;
    color: rgba(153, 153, 153, 1);
    font-size: 28px;
  }
}
.main{
  padding: 100px 30px 0;
  .content{
    color: rgba(16, 16, 16, 1);
    font-size: 32px;
    margin: 30px 0 60px;
    font-weight: 600;
  }
  .wx_btn{
    width: 100%;
    height: 88px;
    border-radius: 10px;
    background: rgba(238, 46, 46, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 32px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    img{
      width: 48px;
      height: 48px;
      margin-right: 10px;
    }
  }
}
</style>
